<template>
  <div class="dev-item">
    <div class="wl-title2" v-if="false">
      <div class="gang"></div>
      <div class="t">
        <!-- PSC水龙头 -->
        {{ $t("dev.box3T1") }}
      </div>
      <div class="gang"></div>
    </div>
    <div class="getTokenBox" v-if="false">
      <input type="text" />
      <div class="but">
        <!-- 给我PSC币 -->
        {{ $t("dev.box3But") }}
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-xiala"></use>
        </svg>
      </div>
    </div>
    <div class="m-title4">
      <div class="t">
        <!-- 浏览相关文档 -->
        {{ $t("dev.box3T2") }}
      </div>
    </div>
    <div class="wordList">
      <div
        class="item"
        :class="{ miniItem: moreList[index] }"
        v-for="(item, index) in $t('dev.box3ItemList')"
        :key="index"
      >
        <div class="t">
          <img
            :src="require(`../../assets/images/v4/dev/devbox3${index + 1}.png`)"
            alt=""
          />
          <div class="span">{{ item.title }}</div>
        </div>
        <div class="ul">
          <div class="li" v-for="(it, ind) in item.ul" :key="ind">
            <a href="javascript:;" @click="uti.goPath(it.url)">
              <div class="key">
                {{ it.key }}
              </div>
            </a>
            <div class="val">{{ it.val }}</div>
          </div>
        </div>
        <div class="more" @click="clic(index)">
          <svg class="icon" v-if="moreList[index]" aria-hidden="true">
            <use xlink:href="#icon-shuangjiantouxia"></use>
          </svg>
          <svg class="icon" v-else aria-hidden="true">
            <use xlink:href="#icon-shuangjiantoushang"></use>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Store from "../../store";
export default {
  data() {
    return {
      uti: Store.uti,
      store: Store.state,
      moreList: [true, true, true, true, true, true],
    };
  },
  methods: {
    clic(index) {
      this.moreList[index] = !this.moreList[index];
      this.moreList = [...this.moreList];
    },
  },
};
</script>

<style lang="less" scoped>
.dev-item {
  // background-image: url("../../assets/images/v4/dev/box3BG2.png"),
  //   url("../../assets/images/v4/home/box7LB.png");
  background-position: right bottom, left 280px;
  background-repeat: no-repeat;
  padding-top: (50 / 3.75vw);
  padding-bottom: (50 / 3.75vw);
  box-sizing: border-box;
  text-align: center;
  .getTokenBox {
    margin: 0 auto;
    width: 758px;
    height: 74px;
    background: rgba(235, 253, 255, 0);
    border: 1px solid #015fea;
    // border-image: linear-gradient(90deg, #015fea, #00bffa) 1 1;

    border-radius: 10px;
    display: flex;
    margin-top: 77px;
    margin-bottom: 239px;
    input {
      flex: 1;
      border: none;
      outline: none;
      background: none;
      font-weight: 400;
      padding: 0 19px;
    }
    .but {
      padding: 0 40px;
      display: inline-block;
      height: 74px;
      background: linear-gradient(90deg, #015eea, #00c0fa);
      border-radius: 10px;
      line-height: 74px;
      font-size: 29px;
      font-weight: 400;
      color: #ffffff;
    }
  }
  .wordList {
    // padding-top: 36px;
    padding: 0 (14 / 3.75vw);
    .item {
      // height: 240px;
      background: #ffffff;
      box-shadow: 0px (1.67 / 3.75vw) (6.67 / 3.75vw) 0px
        rgba(194, 211, 235, 0.5);
      border-radius: (6.67 / 3.75vw);
      padding: (16 / 3.75vw) 0 (15 / 3.75vw);
      box-sizing: border-box;
      margin-top: (26 / 3.75vw);
      position: relative;
      text-align: center;
      transition: 0.3s;
      // max-height: 9999px;

      .t {
        display: inline-flex;
        align-items: center;
        img {
          width: (44 / 3.75vw);
          height: (44 / 3.75vw);
        }
        .span {
          font-size: (18 / 3.75vw);
          font-weight: Medium;
          color: #333333;
          display: inline-block;
        }
      }
      .ul {
        // display: flex;
        // flex-wrap: wrap;
        .li {
          text-align: left;
          .key {
            text-align: center;
            font-size: (14 / 3.75vw);
            font-weight: 500;
            color: #0168ec;
            margin-top: (27 / 3.75vw);
          }
          .val {
            font-size: (12 / 3.75vw);
            font-weight: 400;
            color: #999999;
            margin-top: (8 / 3.75vw);
            width: 243px;
            text-align: center;
            margin: 0 auto;
          }
        }
      }
      .more {
        position: relative;
        bottom: (0 / 3.75vw);
        width: 100%;
        margin-top: (20 / 3.75vw);
        border-radius: 0 0 (6.67 / 3.75vw) (6.67 / 3.75vw);
        background-color: #fff;
        padding-bottom: (10 / 3.75vw);
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .miniItem {
      max-height: (216.67 / 3.75vw);
      overflow: hidden;
      .more {
        position: absolute;
      }
    }
  }
}
</style>
