<template>
  <div class="wl-dev">
    <dev-box-1></dev-box-1>
    <dev-box-2></dev-box-2>
    <dev-box-3></dev-box-3>
  </div>
</template>
<script>
import DevBox1 from "./dev-box1.vue";
import DevBox2 from "./dev-box2.vue";
import DevBox3 from "./dev-box3.vue";
export default {
  components: { DevBox1, DevBox2, DevBox3 },
  data() {
    return {};
  },
};
</script>
