<template>
  <div class="dev-item">
    <div class="m-title">
      <!-- 开发者主页 -->
      {{ $t("dev.box1T") }}
    </div>
    <div class="m-p">
      <!-- Polysmartchain.com
        帮助您通过PSC编写关于基本概念和开发堆栈的相关文档，还有一些教程让您开始和运行。 -->
      {{ $t("dev.box1P") }}
    </div>
    <div class="m-but2" @click="uti.goPath(store.link.dev.box1But)">
      <!-- 开始  -->
      {{ $t("dev.box1But") }}
    </div>
  </div>
</template>
<script>
import Store from "../../store";
export default {
  data() {
    return {
      uti: Store.uti,
      store: Store.state,
    };
  },
};
</script>

<style lang="less" scoped>
.dev-item {
  height: (357.33 / 3.75vw);
  background: url("../../assets/images/v4/dev/box1Bg.png"),
    linear-gradient(0deg, #dae6f2, #edf4fc);
  background-size: (194 / 3.75vw) (194 / 3.75vw), 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  padding-top: (115 / 3.75vw);
  padding-bottom: (52 / 3.75vw);
  box-sizing: border-box;
  text-align: center;
  .m-p {
    margin-top: (22 / 3.75vw);
    padding: 0 (14 / 3.75vw);
  }
  .m-but2 {
    margin-top: (6 / 3.75vw);
  }
}
</style>
