<template>
  <div class="dev-item">
    <div class="m-title4">
      <div class="t">
        <!-- PolySmartChain 网络 -->
        {{ $t("dev.box2T") }}
      </div>
    </div>
    <div class="networkList">
      <div
        class="item"
        v-for="(item, index) in $t('dev.box2Network')"
        :key="index"
      >
        <div class="li">
          <div class="imgBox">
            <img src="../../assets/images/logo.png" alt="" />
          </div>
          <div class="t">
            <!-- PSC主网 -->
            {{ item.title }}
          </div>
        </div>
        <div class="li">
          <div class="l">
            <div class="key">
              <!-- 链ID -->
              {{ item.chainIdKey }}：
            </div>
            <div class="val">
              <!-- 6999 -->
              {{ item.chainIdVal }}
            </div>
          </div>
          <div class="r">
            <div class="span">
              <div class="key">
                <!-- 货币 -->
                {{ item.tokenKey }}：
              </div>
              <div class="val lan">
                <!-- PSC -->
                {{ item.tokenVal }}
              </div>
            </div>
          </div>
        </div>
        <div class="li">
          <div class="l">
            <div class="key">
              <!-- RPC -->
              {{ item.rpcKey }}：
            </div>
            <div class="val">
              <!-- https://seed0.PolySmartChain.com -->
              {{ item.rpcVal }}
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-gantanhao-xianxingyuankuang"></use>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="logo">
      <img src="../../assets/images/v4/dev/box2Logo.png" alt="" />
    </div>
  </div>
</template>
<script>
import Store from "../../store";
export default {
  data() {
    return {
      uti: Store.uti,
      store: Store.state,
    };
  },
};
</script>

<style lang="less" scoped>
.dev-item {
  text-align: center;
  // background-color: pink;
  // height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  padding-top: (37 / 3.75vw);
  // padding-bottom: 142px;
  box-sizing: border-box;
  text-align: center;
  .networkList {
    margin-top: (30 / 3.75vw);
    padding: 0 (14 / 3.75vw);
    .item {
      height: (208.33 / 3.75vw);
      background: #ffffff;
      box-shadow: 0px (2.67 / 3.75vw) (6.67 / 3.75vw) 0px
        rgba(194, 211, 235, 0.5);
      border-radius: (6.67 / 3.75vw);
      margin-top: (0 / 3.75vw);
      padding: (19 / 3.75vw) (9 / 3.75vw);
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .li {
        display: flex;
        text-align: left;
        align-items: center;
        .l {
          flex: 1;
        }
        .r {
          flex: 1;
          display: flex;
          align-items: center;
          .span {
            flex: 1;
          }
          .but {
            padding: 0 36px;
            display: flex;
            height: 54px;
            line-height: 54px;
            background: linear-gradient(-90deg, #10c1fc, #3f65f9);
            box-shadow: 0px 5px 20px 0px rgba(79, 136, 213, 0.5);
            border-radius: 27px;
            font-size: 20px;
            font-weight: 400;
            color: #ffffff;
          }
        }
        .imgBox {
          width: (51 / 3.75vw);
          height: (51 / 3.75vw);
          background: #ffffff;
          box-shadow: 0px 5px 20px 0px rgba(194, 211, 235, 0.5);
          border-radius: 50%;
          text-align: center;
          line-height: 64px;
          img {
            width: (38 / 3.75vw);
            margin-top: (8 / 3.75vw);
          }
        }
        .t {
          // height: 28px;
          font-size: (18 / 3.75vw);
          font-weight: 500;
          color: #333333;
          margin-left: (11 / 3.75vw);
        }
        .key {
          height: 19px;
          font-size: (13.33 / 3.75vw);
          font-weight: 400;
          color: #999999;
          display: initial;
        }
        .val {
          height: 25px;
          display: initial;
          font-size: (15.33 / 3.75vw);
          font-weight: bold;
          color: #333333;
          .icon {
            color: #1babfb;
          }
        }
        .lan {
          height: 18px;
          font-size: (15.33 / 3.75vw);
          font-weight: bold;
          color: #0168ec;
        }
      }
    }
  }
  .logo {
    margin-top: (41 / 3.75vw);
    img {
      width: (58 / 3.75vw);
    }
  }
}
</style>
